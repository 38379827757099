@use '../utils/' as *;

.regrid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: em(40);
  .regrid_options {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 0;padding: em(5)
  }
  .iconButton {
    color: #1e74bb;
  }
}

.regrid_column_toggle {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: em(10);
}
.common_class {
  padding: em(15);
  font-size: em(16);
  @include respond(phone) {
    padding: em(10);
    height: em(50) !important;
  }
}

.main_title {
  &:hover {
    color: #1a90ff;
    text-decoration: underline;
  }
}

.ag-cell-focus,
.ag-cell {
  color: #191b1d;
  font-size: rem(14);
  font-weight: 400;
  line-height: 65px;
  font-family: var(--font-poppins);
  border: none !important;
}

.companycell {
  text-transform: capitalize;
  &:hover {
    color: #1a90ff;
  }
}

// .ag-icon {
//   color: rgba(255, 255, 255, 1);
// }

// .ag-picker-field-wrapper {
//   color: rgba(255, 255, 255, 1) !important;
// }

// .ag-popup {
//   color: rgba(255, 255, 255, 1) !important;
// }
// .ag-react-container {
//   background-color: #101010 !important;
// }

// .MuiSvgIcon-root {
//   color: rgba(255, 255, 255, 1) !important;
// }
// .MuiFormLabel-root {
//   color: rgba(255, 255, 255, 1) !important;
// }

.ag-theme-alpine {
  --ag-borders: none;
}
.ag-theme-alpine .ag-header {
  --ag-header-cell-font-weight: 500;
  --ag-header-foreground-color: #1e74bb;
  --ag-header-background-color: none;
}

// .ag-tooltip { 
//   background-color: #1e74bb;color: white;
// }
