@keyframes spin {
  from {
    transform: rotate3d(0, 0, 0, 0);
  }
  to {
    transform: rotate3d(1, 1, 0, 360deg);
  }
}

.box {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.cube {
  position: relative;
  width: 30px;
  height: 30px;
  transform-style: preserve-3d;
  animation: spin 1.5s linear infinite;
}

.face {
  width: 30px;
  height: 30px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: var(--bg);
  font-weight: bold;
  img {
    width: 80%;
  }
}

.front {
  transform: translateZ(15px);
  background-color: var(--light-3);
  border: em(2) solid var(--gray-40);

  // background-color: var(--gray-50);
  img {
    width: 80%;
  }
}

.back {
  background-color: var(--light-3);
  border: em(2) solid var(--gray-40);

  // background-color: var(--gray-50);
  transform: translateZ(-15px) rotateY(180deg);
  img {
    width: 80%;
  }
}

.left {
  background-color: var(--light-3);
  border: em(2) solid var(--gray-40);
  // background-color: var(--gray-60);
  transform: translateX(-15px) rotateY(-90deg);
  img {
    width: 80%;
  }
}

.right {
  background-color: var(--light-3);
  border: em(2) solid var(--gray-40);
  // background-color: var(--gray-60);
  transform: translateX(15px) rotateY(90deg);
  img {
    width: 80%;
  }
}

.top {
  transform: translateY(-15px) rotateX(90deg);
  border: em(2) solid var(--gray-40);
  background-color: var(--light-3);
  // background-color: var(--gray-80);
  img {
    width: 80%;
  }
}

.bottom {
  transform: translateY(15px) rotateX(-90deg);
  background-color: var(--light-3);
  border: em(2) solid var(--gray-40);
  // background-color: var(--gray-80);
  img {
    width: 80%;
  }
}

@media (prefers-reduced-motion: reduce) {
  .cube {
    animation: none;
    transform: rotate3d(1, 1, 0, 45deg);
  }
}
