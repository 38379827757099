@use '../utils/' as *;

.table {
  background-color: var(--main-10);
  width: 40%;
  @include respond(tab-land) {
    width: 80%;
  }
  &.-success {
    background-color: var(--success-10);
  }
  .title {
    color: var(--bg);
    background: #be93c5;
    background: -webkit-linear-gradient(to left, #7bc6cc, #be93c5);
    background: linear-gradient(to left, #7bc6cc, #be93c5);
    display: flex;
    text-align: center;
    justify-content: center;
    h4 {
      margin: 0;
      padding: em(5);
    }
  }
  .container {
    width: 100%;
    height: 400px;
    .sheet {
      width: 100%;
      height: 100%;
    }
  }
}
