@use '../utils' as *;

.re-card {
  display: flex;
  position: relative;
  flex-direction: column;
  align-content: flex-start;
  width: em(300);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  &.-coming {
    &:hover {
      cursor: default;
    }
  }

  @include respond(tab-port) {
    .card-img {
      img {
        object-fit: cover;
      }
    }
  }
  > .imgbox {
    width: 100%;
    overflow: hidden;
    min-height: em(230);

    > .banner {
      position: absolute;
      border-top-left-radius: em(8);
      border-bottom-right-radius: em(100);
      height: em(45);
      z-index: 50;
      > .info {
        width: fit-content;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        p {
          display: inline;
          height: 100%;
          width: 100%;
          font-family: var(--font-mont);
          font-weight: 600;
          font-size: 0.8rem;
          color: var(--white);
          padding: em(10) em(40) em(0) em(16);
          text-transform: uppercase;
        }
      }

      &.-coming {
        background-color: var(--new);
      }
      &.-available {
        background-color: var(--main);
      }
      &.-soldout {
        background-color: var(--secondary-60);
      }
    }

    > .img {
      height: em(220);
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      border-radius: em(8) em(8) 0 0;
      transition: transform 0.6s ease;
      &:hover {
        transform: scale(1.05);
      }
      &.-soldout {
        filter: grayscale(80%);
        &:hover {
          filter: grayscale(0);
        }
      }
    }
  }
  > .content {
    display: flex;
    flex-direction: column;
    > .address {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 0 em(6);

      div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-grow: 4;
        text-align: left;
        font-weight: 600;
        padding-left: em(4);
        margin: 0;
      }
      .youtube {
        flex: auto;
        overflow: visible;
        border-radius: em(5);
        background-color: var(--light-3);
        transform: perspective(2000px) rotateY(20deg);
        // box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        transition: transform 1s ease 0s;

        &:hover {
          transform: perspective(3000px) rotateY(5deg);
        }
      }
    }
    > .tokens,
    > .inv {
      display: flex;
      justify-content: space-between;
      padding: em(6) em(8);
      div {
        font-size: 0.9rem;
        color: var(--main);
      }
      div:last-child {
        font-weight: 600;
      }
    }

    > .yield {
      display: flex;
      justify-content: space-between;
      background-color: var(--light);
      color: var(--main);
      padding: em(10) em(6);
      div {
        font-size: 1rem;
        font-weight: bold;
      }
      div:last-child {
        font-weight: 600;
      }
    }
  }
}

.team {
  display: flex;
  justify-content: center;
  padding: em(20);
  .teamcard {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(em(200), 1fr));
    width: 95%;
    place-items: center;
    column-gap: em(10);
    row-gap: em(10);
    .member {
      width: em(200);
      height: em(300);
      border-radius: em(25);
      display: flex;
      flex-direction: column;
      row-gap: em(20);
      .avatar {
        border-radius: em(25);
        height: em(200);
        width: 100%;
        display: flex;

        img {
          border-radius: em(25);
          width: 100%;
          object-fit: center;
          height: 100%;
        }
      }
      .info {
        &:hover {
          cursor: pointer;
        }
        font-family: var(--font-inter);
        font-size: rem(14);
        font-style: normal;
        font-weight: 400;
        line-height: 24.219px;
      }
    }
  }
}

.services {
  display: grid;
  gap: em(20);
  grid-template-columns: repeat(3, 1fr);
  @include respond(tab-land) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(phone) {
    grid-template-columns: repeat(1, 1fr);
    padding: em(0);
  }

  &.-vision {
    display: flex;
    width: 80%;
    @include respond(tab-land) {
      width: 100%;
      justify-content: space-between;
    }
    @include respond(tab-port) {
      flex-direction: column;
      padding: 0;
    }
    @include respond(phone) {
      width: 100%;
    }
  }

  .servicecard {
    background: #4c93b1;
    background: radial-gradient(90deg, #4c93b1, #336e98, #1f4a7b);
    background: -webkit-radial-gradient(90deg, #4c93b1, #336e98, #1f4a7b);
    width: em(350);
    display: flex;
    position: relative;
    border-radius: em(15);
    @include respond(tab-port) {
      width: 300px;
    }
    @include respond(phone) {
      width: 350px;
    }
    @include respond(phone-small) {
      width: 250px;
    }
    flex-direction: column;
    justify-content: space-between;
    border-radius: em(15);
    .service {
      position: relative;
      border-radius: em(15) em(15) 0 0;
      padding: em(15) em(5);
      display: flex;
      align-items: center;
      vertical-align: center;

      .text {
        color: white;
        font-family: var(--inter);
        font-size: rem(18);
        font-style: normal;
        font-weight: 500;
        line-height: 40.6px; /* 115.2% */
        letter-spacing: -0.48px;
        text-transform: uppercase;
      }
      .icon {
        @include respond(phone) {
          display: none;
        }
        width: em(30);
        height: em(30);
        margin-right: em(10);
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .pitch {
      &.-defi {
        background: rgba(22, 39, 47, 0.5);
      }
      padding: em(2);
      .text {
        color: white;
        font-family: var(--font-inter);
        font-size: rem(16);
        font-style: normal;
        font-weight: 200;
        line-height: 24.058px;
        letter-spacing: -0.2px;
        padding: em(4);
      }
    }
    .tags {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      @include respond(tab-port) {
        grid-template-columns: repeat(2, 1fr);
        gap: em(10);
      }
      @include respond(phone) {
        grid-template-columns: repeat(2, 1fr);
        gap: em(10);
      }

      height: max-content;
      border-radius: 0 0 em(15) em(15);
      border-top: em(1) solid var(--bg);
      padding: em(10);
      gap: em(5);
      .tag {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-items: center;
        background: var(--bg);
        overflow: hidden;
        word-wrap: break-word;
        padding: em(4);
        font-size: rem(10);
        border-radius: em(25);
        height: em(50);
      }
    }
  }
  .visioncard {
    width: em(350);
    display: flex;
    position: relative;
    border-radius: em(15);
    padding: em(10);
    align-items: baseline;
    @include respond(phone) {
      width: em(300);
    }
    flex-direction: column;
    row-gap: em(10);

    .service {
      position: relative;
      display: flex;
      align-items: center;

      .text {
        font-family: var(--font-inter);
        font-size: 28px;
        font-weight: 500;
        line-height: em(19.683);
      }
      .icon {
        @include respond(phone) {
          display: none;
        }
        width: em(40);
        height: em(40);
        margin-right: em(10);
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .pitch {
      .text {
        font-family: var(--font-inter);
        font-size: 19.687px;
        font-style: normal;
        font-weight: 300;
        line-height: 24.219px;

        line-height: 24.058px;
        letter-spacing: -0.2px;
        padding: em(4);
      }
    }
    .tags {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      @include respond(phone) {
        grid-template-columns: repeat(2, 1fr);
        gap: em(10);
      }

      height: max-content;
      border-radius: 0 0 em(15) em(15);
      border-top: em(1) solid var(--text);
      padding: em(10);
      gap: em(5);
      .tag {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-items: center;
        background: var(--text-2);
        &.-defi {
          background: rgba(22, 39, 47, 1);
        }
        color: white;
        padding: em(4);
        font-size: rem(10);
        border-radius: em(25);
        height: em(50);
      }
    }
  }
}
