@use '../utils/' as *;

.relisting-input {
  display: flex;
  width: em(500);
  font-size: small;
  &.-admin {
    color: var(--main-1);
  }
  @include respond(tab-land) {
    width: em(300);
  }
  > .checkbox {
    > .label {
      font-size: rem(16);
      .input{ 
        margin:0 em(5);

      }
    }
  }

  > .details {
    width: 100%;

    &.re {
      display: flex;
      flex-direction: column;
      height: em(115);
      background-color: var(--light);
      border-radius: em(15);
      color: var(--main);
      font-weight: var(--font-weight-bold);
      padding: 0 em(10);
      overflow: hidden;
      p {
        color: var(--text-2);
      }
    }
  }
  .textarea {
    border: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: em(10);
    padding: em(12);
    width: 100%;
    height: em(100);
    outline: em(1) solid;
    caret-color: var(--text-1);

    &:focus {
      outline: 2px solid var(--main);
    }
  }
}
