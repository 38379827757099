@use '../utils/' as *;

.terms {
  display: flex;
  position: relative;
  margin: em(20) 0;
  width: 100%;
  justify-content: center;
  .info {
    font-family: var(--inter);
    font-size: rem(14);
    font-weight: 200;
    color: var(--text-4);
    display: flex;
    text-align: justify;

    flex-direction: column;
    row-gap: em(20);
    width: 60%;
    &.-acc {
      min-height: 50vh;
    }
    @include respond(tab-port) {
      width: 90%;
    }
    .docblock {
      .docdetail {
        &.-complaints {
          ul {
            li {
              list-style: none;
            }
          }
        }
        a {
          color: var(--main-1);
        }
        ul {
          li {
            list-style-type: disc;
            .innerlist {
              li {
                list-style-type: square;
                margin-left: em(10);
              }
            }
          }
        }
      }
      .docdate {
        font-size: rem(12);
        font-style: italic;
        font-weight: 300;
        margin: em(4) 0;
      }

      .doctitle,
      .doclist {
        font-weight: 400;
        font-size: rem(16);
        text-align: center;
        padding: em(4) 0;
        &.-left {
          text-align: left;
        }
        &.-acc {
          text-align: left;
          display: flex;
          justify-content: space-between;
          .faqtitle {
            width: 80%;
          }
        }
      }
      .doclist {
        display: list-item;
        text-align: left;
      }
    }
    .youtubelink {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;
      margin: em(40) 0 em(0) 0;
      .youtube-embed {
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .pointer {
    z-index: 5000;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: em(10);
  }
}
