@use '../utils/' as *;
.affiliate {
  display: flex;
  flex-direction: column;
  row-gap: em(40);
  justify-content: center;
  align-items: center;
  font-family: var(--font-inter);
  font-size: rem(14);
  font-style: normal;
  font-weight: 400;
  line-height: 24.219px;
  margin: em(20) 0;
  .affiliate_pitch {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: em(10);
    .heading {
      font-size: 19.687px;
      font-style: normal;
      font-weight: 400;
      line-height: 24.219px;
    }
    .details {
      width: 50%;
      @include respond(tab-land) {
        width: 80%;
      }
      @include respond(phone) {
        width: 100%;
      }
      text-align: justify;
    }
  }
  .affiliate_plan {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    column-gap: em(40);
    @include respond(tab-port) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      row-gap: em(40);
    }
    .affiliate_process {
      display: flex;
      flex-direction: column;
      row-gap: em(10);
      width: 30%;
      @include respond(tab-land) {
        width: 40%;
      }
      @include respond(tab-port) {
        width: 60%;
        column-gap: 0;
      }
      @include respond(phone) {
        width: 80%;
      }
      @include respond(phone-small) {
        width: 80%;
      }

      .heading {
        font-size: rem(32);
        @include respond(phone) {
          font-size: rem(20);
        }
        font-style: normal;
        font-weight: 400;
        line-height: 24.219px;
        text-align: center;
        padding: em(5);
      }
      .details {
        @include respond(tab-port) {
          flex-direction: column;
          row-gap: em(40);
        }
        .row_left {
          .row {
            display: flex;
            border: em(1) solid var(--gray-40);
            // padding: em(10);
            column-gap: 0;
            .header {
              text-align: left;
              padding: em(10);
              flex: 1;
              border-right: em(1) solid var(--gray-40);
            }
            .value {
              text-align: left;
              flex: 1;
              padding: em(10);
            }
          }
        }
        // width: 80%;
        // justify-content: space-between;
        // text-align: justify;
        // column-gap: em(40);
      }
    }
  }

  .affiliate_share {
    background: rgba(43, 171, 225, 0.1);
    padding: em(10);
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: em(10);
    width: 50%;
    @include respond(tab-land) {
      width: 80%;
    }
    border-radius: em(15);
    .heading {
      font-size: 19.687px;
      font-style: normal;
      font-weight: 400;
      line-height: 24.219px;
    }
    .details {
      display: flex;
      @include respond(phone) {
        flex-direction: column;
        row-gap: em(20);
      }
      align-items: center;
      justify-content: center;

      .link_box {
        margin: 0 em(10);
        border: 1px solid var(--main-1);
        border-radius: em(5);
        padding: em(5);
        &:hover {
          border: 2px solid var(--main-1);
          cursor: pointer;
        }
      }
    }
  }

  .affiliate_stats {
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: em(20);
    width: 100%;
    justify-content: center;
    align-items: center;
    .heading {
      font-size: rem(32);
      @include respond(phone) {
        font-size: rem(20);
      }
      font-style: normal;
      font-weight: 400;
      line-height: 24.219px;
    }
    .stats {
      display: flex;
      width: 90%;
      padding: em(5);
      column-gap: em(20);
      @include respond(tab-port) {
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      .row_left,
      .row_right {
        width: 50%;
        @include respond(tab-port) {
          width: 80%;
        }
        .row {
          display: flex;
          @include respond(phone) {
            flex-direction: column;
          }
          border: em(1) solid var(--gray-40);
          column-gap: 0;
          @include respond(tab-land) {
            height: em(60);
            width: 100%;
          }
          @include respond(phone) {
            height: auto;
            width: 100%;
          }

          .header {
            text-align: left;
            padding: em(10);
            border-right: em(1) solid var(--gray-40);
            flex: 3;
          }
          .value {
            text-align: end;
            font-weight: 600;
            @include respond(phone) {
              text-align: left;
            }
            flex: 1;
            padding: em(10);
          }
        }
      }
    }
  }
}
