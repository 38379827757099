@use '../utils/' as *;

.blog_card {
  width: em(400);
  height: em(520);
  border: 1px solid #ffffff;
  border-radius: 5px;
  @include respond(phone) {
    width: 100%;
  }
  .img_div {
    padding: em(5);
    width: 100%;
    height: em(220);
    img {
      width: 100% !important;
      height: 100%;
      border-radius: em(3) em(3) 0 0;
    }
  }
  .content_div {
    width: 100%;
    .content_div_inner {
      width: 100%;
      padding: em(20);
      .tags {
        display: flex;
        column-gap: em(5);
        span {
          background: rgba(203, 194, 247, 0.112);
          padding: em(2) em(8);
          border-radius: em(3);
          font-size: em(12);
          font-weight: 400;
          color: #1976d2;
        }
      }
      h3 {
        font-size: em(22);
        font-weight: 600;
        @include respond(phone) {
          font-size: em(20);
        }
      }
      p {
        font-size: em(16);
        opacity: 0.7;
      }
      .content_footer {
        display: flex;
        justify-content: space-between;
        .timestamp {
          font-size: em(16);
          font-weight: 200;
          opacity: 0.7;
        }
        .link {
          font-size: em(16);
          color: #1976d2;
          opacity: 0.7;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
