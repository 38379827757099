@use '../utils/' as *;

.button {
  color: var(--white);
  border-radius: em(50);
  border: em(1) solid var(--main-1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: em(40);
  padding: 0 em(20);
  font-size: rem(14);
  transition: all 0.3s ease-in-out;
  background-color: var(--main);
  &:hover {
    background-color: var(--secondary);
    outline: none;
    text-decoration: none;
  }

  &:focus {
    outline-style: solid;
    outline-color: transparent;
    box-shadow: 0 0 0 em(1) var(--main-1);
  }

  &.lg {
    min-width: 100%;
  }
  &.sm {
    min-width: 5ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.wide {
    width: 80%;
  }
  &.auth {
    width: 90%;
  }

  &.radmd {
    border-radius: em(25);
  }

  &.-reverse {
    color: var(--bg);
    background-color: var(--text);
    border: none;
    &:hover {
      color: var(--text);
      background-color: var(--bg);
    }
  }
  &.-secondary {
    color: var(--main);
    background-color: var(--bg);
    border: em(1) solid var(--main);
    &:hover {
      color: var(--bg);
      background-color: var(--main);
    }
  }
  &.-landing {
    color: var(--white);
    background-color: #2babe1;
    &:hover {
      background-color: var(--secondary);
      outline: none;
      text-decoration: none;
    }
  }

  &.-landingRev {
    color: #2babe1;
    background-color: var(--white);
    border: none;
    &:hover {
      color: var(--white);
      background-color: var(--secondary);
      outline: none;
      text-decoration: none;
    }
  }
  &.-white {
    color: var(--black);
    background-color: var(--white);
    border: none;

    &:hover {
      color: var(--white);
      background-color: #2babe1;
      outline: none;
      text-decoration: none;
    }
  }
  &.-signup {
    color: var(--white);
    font-weight: 600;
    border-radius: em(5);
  }
  &.-mainCta {
    color: var(--white);
    border-radius: em(5);
    font-weight: 600;
    font-size: em(20);
    padding: em(5) em(25);
    @include respond(phone) {
      font-size: em(16);
      padding: em(4) em(20);
    }
  }

  &.-disabled {
    color: var(--gray-40);
    border: em(1) solid var(--gray-70);
    background-color: var(--gray-70);
    &:hover {
      color: var(--bg);
      background-color: var(--gray-80);
    }
  }
  &.-simple {
    color: var(--text);
    border: none;
    border-radius: 0;
    min-width: 0;
    min-height: 0;
    padding: 0;
    line-height: 0;
    &:hover {
      color: var(--main);
      background-color: none;
      border: none;
    }
    &:focus {
      outline-style: none;
      box-shadow: none;
    }
  }
  &.-listing {
    color: var(--main);
    border: em(2) solid var(--text-300);
    background-color: var(--light-3);
    &:hover {
      background-color: var(--text);
      color: var(--bg);
    }
    &:focus {
      outline-style: solid;
      outline-color: transparent;
      box-shadow: 0 0 0 2px var(--text-600);
    }
  }
  &.-success {
    color: var(--success-40);
    border: em(2) solid var(--success-40);
    &:hover {
      background-color: var(--success-40);
      color: var(--bg);
    }
    &:focus {
      outline-style: solid;
      outline-color: transparent;
      box-shadow: 0 0 0 1px var(--success-50);
    }
  }
  &.-blocked {
    color: var(--gray-40);
    border: em(1) solid var(--gray-40);
    background-color: var(--gray-70);
    &:hover {
      background-color: var(--gray-70);
      color: var(--bg);
    }
    &:focus {
      outline-style: solid;
      outline-color: transparent;
      box-shadow: 0 0 0 1px var(--gray-50);
    }
  }
  &.-pay {
    width: 90%;
    color: var(--main);
    font-weight: bold;
    border: em(2) solid var(--main-1);
    background-color: var(--bg);
    text-align: left;
    vertical-align: baseline;

    span {
      padding-right: em(5);
      align-items: center;
    }

    &:hover {
      background: var(--light);
      color: var(--main);
    }
    &:focus {
      outline-style: solid;
      outline-color: transparent;
      box-shadow: 0 0 0 em(1) var(--light);
    }
  }

  &.-loading {
    width: 90%;
    color: var(--gray-60);
    font-weight: bold;
    border: em(2) solid var(--gray-40);
    background-color: var(--bg);
    font-size: rem(20);
    text-align: left;
    vertical-align: baseline;

    span {
      padding-right: em(5);
      align-items: center;
    }

    &:hover {
      background: var(--light);
      color: var(--main-1);
    }
    &:focus {
      outline-style: solid;
      outline-color: transparent;
      box-shadow: 0 0 0 em(1) var(--main-1);
    }
  }
  &.-loader {
    color: var(--main);
    border-radius: em(25);
    border: em(2) solid var(--main-60);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    padding: 0.25em 0.5em;
    min-width: 10ch;
    min-height: em(16);
    transition: 220ms all ease-in-out;
  }
  &.-emailForm {
    border-radius: 0 em(10) em(10) 0;
    font-size: rem(14);
    @include respond(phone) {
      // border-radius: em(25);
    }
  }
  &.-buyToken {
    color: white;
    border-radius: em(25);
    border: em(2) solid var(--main-60);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    padding: em(10) em(20);
    min-width: 10ch;
    min-height: em(16);
    transition: 220ms all ease-in-out;
  }
}
