@use '../utils/' as *;

:root {
  --font-weight-regular: 400;
  --font-weight-semibold: 500;
  --font-weight-bold: 600;
  --letter-spacing-tighter: -0.05em;
  --letter-spacing-tight: -0.025em;
  --letter-spacing-normal: 0;
  --letter-spacing-wide: 0.025em;
  --letter-spacing-wider: 0.05em;
  --letter-spacing-widest: 0.1em;
}
h1,
h2,
h3 {
  line-height: 1.1;
  margin-top: 0;
}

a,
a:visited,
a:active {
  text-decoration: none;
  color: inherit;
}

ul,
ol,
li {
  list-style: none;
}

ul {
  padding: 0;
}

body {
  font-weight: 400;
  line-height: 1.75;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: var(--font-weight-regular);
  line-height: 1.3;
}

h1 {
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}
p {
  font-size: rem(16);
}
small,
.text_small {
  font-size: 0.8rem;
}
