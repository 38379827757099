@use '../utils/' as *;

.notfoundpage:before {
  content: '';
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;
  display: block;
  -webkit-filter: blur(em(2));
  -moz-filter: blur(em(2));
  -o-filter: blur(em(2));
  -ms-filter: blur(em(2));
  filter: blur(em(2));
  background-image: url('../../assets/three_blocks_banner.webp');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.notfoundpage {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 0;
  margin-left: em(20);
  margin-right: em(20);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .access {
    height: 50%;
    display: flex;
    width: 50%;
    border-radius: em(5);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1c92d2;
    background: -webkit-linear-gradient(to right, #f2fcfe, #1c92d2);

    background: linear-gradient(to right, #f2fcfe, #1c92d2);
    margin: em(5);
    padding: em(10);
  }
}
