@use '../utils/' as *;

.cardproperty {
  width: 40%;
  border-radius: em(5);
  @include respond(tab-land) {
    width: 80%;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;

  &.-about {
    border: none;
    background: none;
  }
  @include respond(tab-land) {
    width: 80%;
    margin-bottom: em(40);
  }
  @include respond(tab-port) {
    width: 90%;
  }
  .caption {
    p {
      margin: 0;
      padding: em(2);
      letter-spacing: em(1);
      font-weight: 600;
    }
    color: var(--main);
    text-align: center;
    margin-bottom: em(5);
  }
  .title {
    color: var(--main);
    display: flex;
    text-align: center;
    justify-content: center;
    h4 {
      margin: 0;
      padding: em(10);
      font-weight: 600;
    }

    &.-about {
      color: var(--main);
      background-color: none;
      text-align: left;
      justify-content: flex-start;
    }
  }
  .info-property {
    &.-about {
      background-color: none;
      background: none;
    }
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: em(10) em(20);
    grid-auto-flow: row;
    margin-bottom: em(20);
    padding: em(10);
    background-color: var(--main-1);
    &.-sub {
      grid-template-columns: 1fr 1fr;
      @include respond(phone) {
        grid-template-columns: 1fr;
      }
    }
    .about {
      transition: transform 1s ease-out;
      -webkit-transition: 1s ease-out;

      padding: em(10) em(15);
      @include respond(phone) {
        padding: em(10) em(5);
      }
      display: flex;
      justify-content: space-between;
      background-color: var(--bg);
      border-radius: em(5);
      white-space: initial;
      word-break: break-word;
      color: var(--gray-90);
      li {
        list-style: disc;
        margin-bottom: em(20);
        color: var(--gray-100);
        font-weight: 600;
      }
      p {
        margin: 0;
        padding: em(2);
        letter-spacing: em(1);
      }
    }

    .stats {
      transition: transform 1s ease-out;
      -webkit-transition: 1s ease-out;
      padding: em(10) em(15);
      display: flex;
      justify-content: space-between;
      text-transform: capitalize;
      background-color: var(--bg);
      border-radius: em(5);
      color: var(--gray-90);
      font-weight: bolder;

      div:first-child {
        flex: 2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 500;
      }
      &.-head {
        font-size: rem(20);
      }
    }
  }
}
