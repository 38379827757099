@use '../utils/' as *;

.marketplace {
  display: flex;
  flex-direction: column;

  // &.-main {
    margin-top: em(40);
  // }
  & .status {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(em(300), 1fr));
    row-gap: em(40);
    justify-items: center;
    margin-bottom: em(20);
  }
}
