@use '../utils/' as *;

.refilelisting-form {
  width: 50%;
  margin: auto;
  @include respond(phone) {
    width: 80%;
  }
  transition: 300ms all ease-in-out;
  font-size: small;

  .file {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .filecontent {
      display: flex;
      flex-direction: column;
      align-items: center;
      .filedata {
        width: 100%;
        margin: em(20) 0;
        .upload-label {
          display: flex;
          justify-content: center;
          text-align: center;
          font-size: medium;
          font-weight: var(--font-weight-bold);
          margin: em(8);
          text-transform: capitalize;
          color: var(--main);
        }
        .upload {
          display: flex;
          flex-direction: column;
          width: 100%;
          &-box {
            border: em(4) dashed var(--gray-50);
            border-radius: em(10);
            flex-direction: column;
            align-items: center;
            padding: em(20);
            outline: none;
            margin: em(20) 0;
            &:hover {
              border-color: var(--main-1);
            }
            transition: 'border .24s ease-in-out';
            &-images {
              display: flex;
              flex-direction: column;
              color: var(--gray-70);
              align-items: center;
              &.reject {
                &:hover {
                  border-color: var(--danger-40);
                }
              }

              .browse {
                background-color: var(--text);
                color: var(--white);
                border-radius: 25px;
                font-size: small;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                line-height: 1.5;
                padding: 0.25em 0.5em;
                min-width: 10ch;
                min-height: 16px;
                transition: 220ms all ease-in-out;
                &:hover {
                  background-color: #0d0e0f;
                }
                &:focus {
                  outline-style: solid;
                  outline-color: transparent;
                  box-shadow: 0 0 0 2px #5e5f61;
                }
              }
            }
            &.accept {
              border-color: var(--success-40);
            }

            &.reject {
              border-color: var(--danger-40);
            }
          }
          .preview {
            &-info {
              padding: em(5);
              display: flex;
              flex-direction: column;
              align-items: center;
              color: var(--main);
              p {
                background-color: var(--light);
                padding: em(10);
                margin: 0;
                font-weight: bold;
              }
              button {
                margin: em(10) 0;
              }
            }
            &-thumbnail {
              display: flex;
              justify-content: center;
              margin: em(20) 0;
              padding: em(10);

              background-color: var(--light);

              .gallery-box {
                display: flex;
                flex-direction: column;
                column-gap: em(5);
                width: em(200);
                box-shadow: 0 4px 6px #0000001a;
                border-radius: em(8) em(8) 0 0;
                transition: transform 0.6s ease;

                .galleryimg {
                  height: em(220);
                  width: 100%;
                  overflow: hidden;
                  position: relative;

                  .remove-icon {
                    height: em(45);
                    position: absolute;
                    right: 0;
                    height: em(45);
                    z-index: 50;
                    &:hover {
                      color: var(--danger-60);
                    }
                  }
                  .number-icon {
                    position: absolute;
                    border-top-left-radius: em(8);
                    border-bottom-right-radius: em(100);
                    z-index: 50;
                    width: em(60);
                    height: em(30);
                    top: 0;
                    text-align: center;
                    font-size: small;
                    background-color: var(--text);
                    color: var(--bg);
                    font-weight: var(--font-weight-bold);
                  }
                  .homepage {
                    position: absolute;
                    z-index: 50;
                    width: 100%;
                    height: em(30);
                    top: 0;
                    text-align: center;
                    font-size: small;
                    background-color: var(--text);
                    color: var(--bg);
                    font-weight: var(--font-weight-bold);
                  }
                  .reimg {
                    width: 100%;
                  }
                }
                .gallerycontent {
                  display: flex;
                  justify-content: center;
                  padding: em(2);
                  > .contentname {
                    font-weight: var(--font-weight-semibold);
                    font-size: small;
                    overflow: hidden;
                    max-width: min-content;
                  }
                }
              }
            }
            &-gallery {
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(em(200), 1fr));
              grid-row-gap: em(80);

              .gallery-box {
                display: flex;
                flex-direction: column;
                column-gap: em(5);
                width: em(200);
                box-shadow: 0 4px 6px #0000001a;
                border-radius: em(8) em(8) 0 0;
                transition: transform 0.6s ease;

                .galleryimg {
                  height: em(220);
                  width: 100%;
                  overflow: hidden;
                  position: relative;
                  .remove-icon {
                    height: em(45);
                    position: absolute;
                    right: 0;
                    height: em(45);
                    z-index: 50;
                    &:hover {
                      color: var(--danger-60);
                    }
                  }
                  .number-icon {
                    position: absolute;
                    border-top-left-radius: em(8);
                    border-bottom-right-radius: em(100);
                    z-index: 50;
                    width: em(60);
                    height: em(30);
                    top: 0;
                    text-align: center;
                    font-size: small;
                    background-color: var(--text);
                    color: var(--bg);
                    font-weight: var(--font-weight-bold);
                  }
                  .reimg {
                    width: 100%;
                  }
                }
                .gallerycontent {
                  display: flex;
                  justify-content: center;
                  padding: em(2);
                  > .contentname {
                    font-weight: var(--font-weight-semibold);
                    font-size: small;
                    overflow: hidden;
                    max-width: min-content;
                  }
                }
              }
            }
          }
        }
      }
    }

    .file-submit {
      display: flex;
      justify-content: space-around;
    }
  }
}

@-webkit-keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}
@keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}
