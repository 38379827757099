@use '../utils/' as *;

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(94, 95, 97, 1);
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  &.open {
    overflow: hidden;
  }

  .content {
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: space-around;
    padding: em(10);
    box-sizing: border-box;
    border-radius: em(10);
    background-color: var(--success-40);
    color: var(--bg);
    font-weight: bold;
    font-size: rem(16);
    text-align: center;
    align-items: center;

    &.-success {
      display: none;
    }

    .info {
      display: flex;
      justify-content: center;
    }
    &.-large {
      flex-direction: column;
      width: 30%;
      padding: em(20);
      gap: em(40);
      background-color: var(--bg);
      color: var(--success-40);
      @include respond(tab-land) {
        width: 50%;
      }
      @include respond(phone) {
        width: 80%;
      }
      & ~ .content {
        display: none;
        &.-success {
          display: block;
        }
      }
    }
    .options {
      display: flex;
      justify-content: space-evenly;
      align-self: stretch;
    }
    .form {
      .tokeninput {
        margin: em(20) 0;
        > div {
          margin-bottom: em(40);
        }
      }
    }

    @include respond(tab-land) {
      width: 40%;
      padding: 0;
    }
    @include respond(phone) {
      width: 70%;
    }
  }
  .remove-icon {
    padding: em(10);
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
