@use '../utils/' as *;

.newsletter {
  &.-header {
    // margin-left: em(150);
    padding: em(10) em(15);

    @include respond(tab-port) {
      width: 80%;
      background: none;
      height: 80%;
    }

    width: 50%;
    height: 90%;
    .subscribe {
      display: none;
      margin-bottom: 0;
      padding: 0;
      justify-content: left;
      .close {
        display: none;
        flex: 0;
      }
    }
    .form {
      padding: 0;
      margin: 0;
      .input {
        input {
          font-size: rem(15);
          color: rgba(255, 255, 255, 1);
          background-color: rgba(34, 38, 40, 0.805);
          @include respond(tab-port) {
            border: 2px solid rgb(203, 243, 253);
          }
          &::placeholder {
            color: rgba(255, 255, 255, 1);
          }
          &:placeholder-shown {
            word-spacing: em(-1);
          }
        }
      }
    }
  }
  @include respond(tab-land) {
    width: 60%;
  }
  @include respond(phone) {
    width: 95%;
    background: none;
  }

  .form {
    display: flex;
    padding: em(5);
    @include respond(phone) {
      padding: 0;
      // flex-direction: column;
    }

    .input {
      flex: 9;
      width: 100%;
      input {
        width: 100%;
        height: em(34);
        font-size: em(15);
        border-radius: em(5) 0 0 em(5);
        background-color: rgba(34, 38, 40, 0.805);
        color: rgba(255, 255, 255, 1);
        &::placeholder {
          color: rgba(255, 255, 255, 1);
        }
        @include respond(phone) {
          font-size: em(14);
          height: em(40) !important;
        }
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: none;
        border: none;
        padding-left: em(20);
        font-weight: 400;
        &:focus {
          outline: 1px solid var(--main-1);
        }
      }
    }
  }
}
.newsletterLayout {
  width: 100%;
  height: em(120);
  @include respond(phone) {
    height: em(60);
  }
  display: flex;
  align-items: center;
  justify-content: center;
}
