@use '../utils/' as *;

.dialog-tokens {
  display: flex;
  justify-content: space-between;
  padding: 0 em(20);
  margin: 0 em(40);
  @include respond(tab-land) {
    flex-direction: column;
    align-items: center;
  }
  @include respond(phone) {
    padding: 0 em(10);
    margin: 0 em(20);
  }
}

.buy-tokens {
  width: 50%;
  @include respond(tab-land) {
    width: 100%;
    margin-bottom: em(80);
  }
  &.-soldout {
    .financials {
      .header {
        color: var(--gray-80);
      }
      .details {
        justify-content: space-evenly;
        width: 50%;
        .price {
          &.-token {
            .tag,
            .fig {
              color: var(--gray-80);
            }
          }
          .tag,
          .fig {
            color: var(--gray-80);
          }
        }
      }
    }
  }
  .financials {
    display: flex;
    flex-direction: column;
    margin-bottom: em(20);
    width: 100%;

    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: var(--text);
      @include respond(phone) {
        justify-content: center;
        margin-bottom: em(20);
      }
      .title {
        font-size: rem(24);
        font-weight: bold;
        padding-right: em(5);
        @include respond(tab-land) {
          font-size: rem(30);
        }
        @include respond(phone) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .status {
        background-color: var(--light-3);
        color: var(--main);
        text-transform: uppercase;
        border-radius: em(15);
        font-weight: bold;
        font-size: xx-small;
        &.-soldout {
          background-color: var(--secondary-20);
          color: var(--secondary-60);
        }
        @include respond(tab-land) {
          font-size: x-small;
        }
        @include respond(phone) {
          align-self: flex-start;
        }
        padding: em(2) em(10);
      }
    }

    .details {
      display: flex;
      width: 80%;
      justify-content: space-around;
      @include respond(tab-land) {
        width: 100%;
      }
      @include respond(phone) {
        flex-direction: column;
        align-items: center;
      }

      .price {
        &.-token {
          .tag,
          .fig {
            // color: var(--success-40);
          }
        }
        .tag {
          font-size: rem(26);
          color: var(--main);
          @include respond(tab-land) {
            font-size: rem(32);
          }
        }
        .fig {
          color: var(--main);
          font-weight: var(--font-weight-bold);
          font-size: rem(12);
          @include respond(tab-land) {
            font-size: rem(18);
          }
        }

        @include respond(phone) {
          margin-bottom: em(20);
        }
      }
    }
  }
}

.info-tokens {
  @include respond(tab-land) {
    width: 100%;
  }
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: em(10) em(20);
  grid-auto-flow: row;
  margin-bottom: em(20);
  font-size: rem(15);
  @include respond(tab-land) {
    font-size: rem(18);
  }
  @include respond(phone) {
    grid-template-columns: 1fr;
  }
  .sc {
    color: var(--main);
    background-color: var(--light);
    font-weight: bold;
    align-items: center;
    padding: em(8);
    border-radius: em(5);
  }
  .stats {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    div:first-child {
      flex: 2;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 300;
    }
  }
}
