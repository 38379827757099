@use '../utils/' as *;

.notice {
  color: var(--main);
  text-align: center;
  padding: em(5) em(15);
  margin: em(10) 0;
}

.update {
  h5 {
    color: var(--text-4);
  }
  h4 {
    background-color: var(--light);
    padding: em(5);
  }
}

.options {
  display: flex;
  justify-content: space-evenly;
  align-self: stretch;
  .signinbutton {
    color: var(--main);
    border-radius: em(25);
    border: em(2) solid var(--main-1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    padding: 0.25em 0.5em;
    min-width: 10ch;
    min-height: em(16);
    transition: 220ms all ease-in-out;
    margin: em(40) 0;
    &.-large {
      min-width: 20ch;
    }

    &:hover {
      background-color: var(--main);
      color: var(--bg);
    }
    &:focus {
      outline-style: solid;
      outline-color: transparent;
      box-shadow: 0 0 0 2px var(--main-2);
    }
  }
}
