@use '../utils/' as *;

.anchor {
  background-color: var(--main-1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bg);
  font-weight: 200;
  padding: em(4);
  &:hover {
    cursor: pointer;
  }
}
.tooltip-container {
  position: relative;
}

.tooltip {
  position: absolute;
  padding: 4px;
  width: 100%;
  transform: translateY(-20%);
  z-index: 1;
  right: 0;
  bottom: 0;
  background-color: var(--main-1);
  color: #fff;
  text-align: center;
  font-size: rem(12);
  padding: em(5);
}
