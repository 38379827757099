@use '../utils/' as *;

// .marquee {
//   position: relative;

//   height: 4vh;
//   overflow-x: hidden;
//   background-color: var(--success-40);
//   transition: 1000ms all ease-in-out;
//   &.-info {
//     background-color: var(--main-2);
//   }
//   &.-alert {
//     background-color: var(--danger-40);
//     @include respond(phone) {
//       display: none;
//     }
//   }
//   &.-hide {
//     visibility: hidden;
//     @include respond(phone) {
//       visibility: hidden;
//     }
//   }
//   .track {
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     color: var(--bg);
//     font-weight: bold;
//     white-space: nowrap;
//     small {
//       text-align: center;
//     }
//   }
// }

.newMarquee {
  display: none;
  background-color: var(--danger-40);
  color: var(--bg);
  @include respond(phone) {
    display: block;
  }
}
