@use '../utils/' as *;

.profile {
  display: flex;
  .menu {
    color: var(--main);
    width: em(200);
    width: 15%;
    background-color: var(--bg);
    border-right: em(1) solid var(--gray-40);
    @include respond(phone) {
      display: none;
    }
    &__list {
      &__item {
        padding: em(10) em(20);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        margin: em(20) em(10);

        &:hover {
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -ms-transform: scale(1.1);
          -o-transform: scale(1.1);
          transform: scale(1.1);
          background-color: var(--main-10);
          border-radius: em(5);
        }
        &:last-child {
          font-weight: 600;
          &:hover {
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
          }
        }
      }
    }
  }
  .personal {
    width: 100%;
    min-height:50vh;
    margin: em(10) 0;
    display: flex;
    flex-direction: column;
    .status {
      display: flex;
      column-gap: em(20);
      background-color: var(--bg);
      padding: em(10);
      align-items: center;

      &.-portfolio {
        align-items: normal;
        justify-content: center;
        column-gap: em(80);
        @include respond(tab-port) {
          flex-direction: column;
          align-items: center;
          row-gap: em(40);
        }
        .inv_value {
          background: rgba(0, 82, 204, 0.05);
          width: em(300);
          @include respond(tab-port) {
            width: em(250);
          }
          padding: em(10);
          border-radius: em(15);
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          font-weight: 600;
          font-size: rem(16);
          color: var(--main);
          row-gap: em(20);

          &.-personalpoints {
            width: max-content;
            height: auto;
            row-gap: em(0);

            .personaldetails {
              display: flex;
              row-gap: em(0);
              flex-direction: column;
              justify-content: center;
              font-size: rem(14);
              font-weight: 400;
              .row {
                display: flex;
                column-gap: 0;
                @include respond(phone) {
                  flex-direction: column;
                }

                .header {
                  padding: em(5);
                  text-align: left;
                  font-weight: 300;
                  column-gap: 0;
                }
                .value {
                  padding: em(5);
                  text-align: end;
                  @include respond(phone) {
                    text-align: left;
                  }
                }
              }
            }
          }
          .inv {
            font-size: rem(14);
          }
          .fig {
            padding: em(2) em(40);
            color: white;
            text-align: center;
            border-radius: em(15);
            background-color: rgba(0, 82, 204, 0.4);
            @include respond(tab-land) {
              font-size: rem(14);
            }
          }
        }
      }
      @include respond(phone-small) {
        padding: em(20) em(4);
        flex-direction: column;
        align-items: center;
      }
      &__avatar {
        display: flex;
        column-gap: em(2);
        @include respond(phone) {
          margin-bottom: em(20);
        }
        & .tag {
          display: flex;
          height: 20%;
          align-items: center;
          background-color: var(--gray-20);
          color: var(--gray-90);
          text-transform: uppercase;
          border-radius: em(15);
          font-weight: 600;
          font-size: rem(8);
          padding: em(5) em(10);
          &.-verified {
            background-color: var(--light-4);
            color: var(--bg);
          }
        }
      }

      &__verify {
        color: var(--success-40);
        font-weight: bold;
        font-size: rem(12);
        margin: 0 em(20);
        text-decoration: underline;
        @include respond(phone-small) {
          display: inline;
          font-size: rem(16);
        }
      }
      &__data {
        display: flex;
        font-weight: 500;
        font-size: rem(12);
        color: var(--main);
        flex-direction: column;
      }
    }
  }
  .info {
    background-color: var(--bg);

    border-radius: em(10);
  }
}
