@use '../utils/' as *;

.blog_page {
  max-width: 1300px;
  // background-color: pink;
  padding: em(30) 0;
  margin: auto;
  margin-top: em(40);
  // background-color: #101010;
  // color: white;
  h1 {
    margin: 0;
    font-size: em(42);
    @include respond(phone) {
      font-size: em(32);
    }
  }
  .main_blog {
    margin: em(30) em(0);
    width: 100%;
    // min-height: em(430);
    border: 1px solid #ffffff;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    .left_box {
      flex: 1 1 55%;
      height: em(430);
      padding: em(5);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: em(3) em(3) 0 0;
      }
      @include respond(phone) {
        flex: 1 1 100%;
        height: em(220);
      }
    }
    .right_box {
      flex: 1 1 45%;
      padding: em(20) em(20) em(20) em(100);
      height: 100%;
      .tags {
        display: flex;
        column-gap: em(5);
        margin-bottom: em(30);
        @include respond(phone) {
          margin-bottom: 0;
        }
        span {
          background: rgba(203, 194, 247, 0.112);
          padding: em(2) em(8);
          border-radius: em(3);
          font-size: em(14);
          font-weight: 400;
          color: #1976d2;
        }
      }
      h3 {
        font-size: em(30);
        font-weight: 600;
        @include respond(phone) {
          font-size: em(22);
        }
      }
      p {
        font-size: em(16);
        opacity: 0.7;
        margin-bottom: em(30);
        @include respond(phone) {
          margin-bottom: em(16);
        }
      }
      .mobile {
        display: none;
        @include respond(phone) {
          display: block;
        }
      }
      .desktop {
        @include respond(phone) {
          display: none;
        }
      }
      .content_footer {
        display: flex;
        justify-content: space-between;
        .timestamp {
          font-size: em(16);
          font-weight: 200;
          opacity: 0.7;
        }
        .link {
          font-size: em(16);
          color: #1976d2;
          opacity: 0.7;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
      }
      @include respond(phone) {
        flex: 1 1 100%;
        padding: em(15);
      }
    }
  }
  .blogs_list {
    margin-top: em(50);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: em(50);
  }
  @include respond(phone) {
    padding: em(15);
  }
}

.loader_div {
  display: flex;
  justify-content: center;
}
