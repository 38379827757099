@use '../utils/' as *;

.toast {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  border-radius: em(10);
  color: var(--bg);
  font-weight: var(--font-weight-bold);
  position: absolute;
  transform: translate(-50%, 0%);
  transition: 1000ms all ease-in-out;
  padding: em(10);
  top: 2%;
  left: 50%;
  width: 20%;
  z-index: 5000;
  
  @include respond(tab-land) {
    width: 40%;
    padding: em(10);
  }
  @include respond(phone) {
    width: 80%;
    padding: em(20);
  }
 
  &.-su {
    background-color: var(--success-40);
  }
  &.-er {
    background-color: var(--secondary-40);
  }
  &.-in {
    background-color: var(--main-1);
  }
  &.-input{ 
    color: var(--danger-50);
    width: 200%;
    bottom: 0;
    left: 0;
    transform: translate(0%, 100%);
    font-size: small;
    font-weight: normal;
    text-align: left;
  }
  > .toast-content {
    padding: em(0) em(6);
  }

  > .remove-icon {
    position: absolute;
    right: 0;
    top: 0;
    padding: em(10);
  }
}
