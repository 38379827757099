@use '../utils/' as *;
.carousel {
  width: 100%;
  height: 100vh;
  .mySwiper2 {
    height: 60%;
    width: 100%;
    .swiper-slide {
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .mySwiper {
    height: 20%;
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 0;

    .swiper-slide {
      background-color: var(--bg);
      width: 25%;
      height: 100%;
      opacity: 0.3;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }
    .swiper-slide-thumb-active {
      opacity: 1;
    }
  }
}
