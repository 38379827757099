@use '../utils/' as *;

.partnerships {
  margin: em(20) em(0);
  padding: em(20) em(80);
  @include respond(tab-port) {
    padding: em(20);
  }
  @include respond(phone-small) {
    padding: em(20) 0;
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: em(20);
  .main {
    display: flex;
    flex-direction: column;
    row-gap: em(20);
    padding: em(20) 0;

    .title-partner {
      text-align: left;
      font-weight: 500;
      font-size: rem(30);
      font-family: var(--font-inter);
      margin: 0;
      border-bottom: em(1) solid lightgray;
      @include respond(phone-small) {
        padding: 0 em(10);
        margin: 0;
      }
    }
    .section {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(em(200), 1fr));
      @include respond(tab-port) {
        grid-template-columns: repeat(auto-fit, minmax(em(150), 1fr));
      }
      @include respond(phone) {
        grid-template-columns: repeat(auto-fit, minmax(em(150), 1fr));
      }
      justify-items: center;
      row-gap: em(40);

      .logocontainer {
        width: em(150);
        height: em(50);
        display: flex;
        justify-content: center;
        align-items: center;
        .logo-partner {
          width: 100%;
          height: auto;
          display: flex;
        }
      }
    }
  }
}
