@use '../utils/' as *;

.hero {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: em(30) 0;
  background: #005aa7; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #fffde4, #005aa7); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f9f8f4dd,
    #1a6bb2a5
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 10vh;
  @include respond(tab-port) {
    flex-direction: column;
    justify-content: space-between;
  }

  .hero-center {
    display: flex;
    flex-direction: column;
    margin: em(20) em(40) em(20) em(60);

    @include respond(tab-port) {
      margin: em(20) em(40) em(60) em(40);
    }
    @include respond(phone-small) {
      margin: em(20) em(10) em(10) em(10);
    }
    @include respond(phone) {
      margin: em(20) em(15) em(10) em(15);
    }

    .msg {
      font-weight: 600;
      font-size: rem(52) !important;
      line-height: 60.742px;
      span {
        font-weight: 800;
      }
      @include respond(tab-land) {
        width: 100%;
      }
      font-weight: 550;
      @include respond(phone) {
        font-size: rem(26) !important;
        line-height: 30.742px;
      }
      @include respond(phone-small) {
        font-size: rem(20);
      }
      font-family: var(--font-inter);
      color: var(--text);
      font-size: 2.2rem;
      margin-bottom: em(10);
    }
    .emailFormMobile {
      display: none;
      margin-top: em(16);
      @include respond(phone) {
        display: block;
      }
    }
  }
  .hero-map {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: em(0) em(80) em(20) em(0);
    @include respond(tab-land) {
      margin: em(0) em(40) em(20) em(0);
      justify-content: space-between;
    }
    @include respond(tab-port) {
      margin: em(0) em(40) em(20) em(40);
    }
    .map {
      margin-top: em(50);
      width: em(640);
      position: relative;
      .map-animation {
        width: em(100);
        position: absolute;
        right: em(120);
        transform: translateX(-50%);
        animation: bounce 2s infinite linear;
        @include respond(phone) {
          width: em(50);
          right: em(50);
        }
      }
      @include respond(tab-land) {
        width: em(500);
      }
      @include respond(tab-port) {
        width: em(570);
      }
      @include respond(phone) {
        width: em(300);
        margin-top: em(10);
      }
    }
    img {
      display: block;
      width: 100%;
      height: auto;
      @include respond(tab-land) {
      }
    }
  }
}

@keyframes bounce {
  0% {
    top: 0; /* Start position */
  }
  50% {
    top: 10px; /* Peak position, adjust as needed */
  }
  100% {
    top: 0; /* End position */
  }
}
