@use '../utils/' as *;

.accordion {
  width: 40%;
  border-radius: em(5);
  @include respond(tab-land) {
    width: 80%;
  }
  @include respond(phone) {
    width: 90%;
  }

  .title {
    color: var(--main);
    display: flex;
    text-align: center;
    justify-content: center;
    h4 {
      margin: 0;
      padding: em(10);
      font-weight: 600;
    }
  }
  .info-property {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    margin-bottom: em(5);
    padding: em(10);
    background-color: var(--main-1);

    .subtotal,
    .stats,
    .total {
      transition: transform 1s ease-out;
      -webkit-transition: 1s ease-out;
      padding: em(10) em(15);
      display: flex;
      justify-content: space-between;
      text-transform: capitalize;
      background-color: var(--bg);
      color: var(--gray-90);
      font-weight: bolder;
      @include respond(phone) {
        flex-direction: column;
        align-items: center;
      }
      div {
        &:first-child {
          flex: 2;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: 500;
          padding-right: em(4);
          @include respond(phone) {
            overflow: auto;
            white-space: initial;text-overflow: unset;
           text-align: center;
          }
        }
      }
    }
    .subtotal {
      div {
        span {
          Button {
            margin: 0 em(4);
          }
        }
      }
    }
    .stats,
    .subtotal {
      margin-bottom: em(10);
      @include respond(phone) {
        font-size: rem(18);
      }
      &:last-child {
        margin-bottom: em(0);
      }
    }

    .subtotal,
    .total {
      font-weight: 600;
      font-size: rem(20);
      @include respond(phone) {
        font-size: rem(24);
      }
    }

    .total {
      div {
        &:first-child {
          font-weight: 600;
          flex: 2;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
