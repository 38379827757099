@use '../utils/' as *;

.container {
  max-width: 114rem;
  padding: 0 8rem;
  @include respond(phone) {
    padding: 0 2rem;
  }
}

.container-landing {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 0 auto;
}
