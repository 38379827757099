@use '../utils/' as *;

.alert {
  &.-content {
    display: flex;
  }
  &.-pay {
    width: 100%;
    > .content {
      color: var(--success-50);
      background-color: var(--success-10);
    }
  }

  &.-disabled {
    > .content {
      color: var(--gray-60);
      background-color: var(--gary-40);
    }
  }
  &.-info {
    > .content {
      color: var(--main);
      font-weight: 300;
    }
  }
  &.-error {
    > .content {
      color: var(--error-50);
      font-weight: 300;
      font-size: rem(10);
      background-color: var(--error-10);
      text-align: left;
    }
  }

  &.-md {
    width: 40%;
    @include respond(phone) {
      width: 90%;
    }
  }
  &.-sm {
    width: 30%;
    > .content {
      padding: em(5);
    }
  }

  margin: em(10);
  > .content {
    display: flex;
    text-align: center;
    vertical-align: baseline;
    align-items: center;
    justify-content: center;
    border-radius: em(16);
    width: 100%;
    padding: em(20);
    font-weight: var(--font-weight-bold);
    background-color: var(--light);
    color: var(--gray-100);
    font-size: em(14);
    align-items: center;
    .icon {
      margin-right: em(20);
    }
    .msg {
      @include respond(tab-port) {
        font-size: small;
      }
    }
  }
}

.alertbanner {
  width: 100vw;
  margin: 0;
  display: flex;
  > .content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light);
    width: 100%;
    padding: em(10) em(20);
    background-color: var(--main);
    font-size: rem(12);
  }
}
