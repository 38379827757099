@use '../utils/' as *;

.property {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-evenly;
  @include respond(tab-land) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: em(40);
  }
}
