@use '../utils/' as *;

.aboutmain {
  display: flex;
  flex-direction: column;
  padding: em(20) em(80);
  @include respond(tab-port) {
    padding: em(20);
  }
  @include respond(phone-small) {
    padding: em(20) 0;
    margin: 0;
  }

  .section {
    margin: 0;
    display: flex;
    justify-content: space-between;
    &.-services {
      background-color: white;
    }
   

    &.-vision {
      align-items: center;
      @include respond(tab-port) {
        background: url('../../assets/bricks_mini.svg');
        background-repeat: no-repeat;
        background-position: right top -20px;
      }
      @include respond(phone) {
        background: url('../../assets/bricks_mini.svg');
        background-repeat: no-repeat;
        background-position: right -150px top -20px;
      }
      @include respond(phone-small) {
        background: none;
      }
    }
    @include respond(phone) {
      padding: auto;
    }
    .info {
      &.-vision {
        @include respond(tab-port) {
          width: 80%;
          backdrop-filter: blur(1px);
        }
      }

      @include respond(phone-small) {
        padding: em(20) em(20);
      }
      .title {
        font-family: var(--font-inter);
        font-size: 28px;
        font-weight: 500;
        line-height: em(19.683);
      }
      .details {
        font-family: var(--font-inter);
        font-size: 19.687px;
        font-style: normal;
        font-weight: 400;
        line-height: 24.219px;

        .contactdata {
          display: grid;
          gap: em(20);
          grid-template-columns: repeat(3, 1fr);
          @include respond(tab-land) {
            grid-template-columns: repeat(2, 1fr);
          }
          @include respond(phone) {
            grid-template-columns: repeat(1, 1fr);
            padding: em(0);
          }
          .officebox {
            background: #4c93b1;
            background: radial-gradient(90deg, #4c93b1, #336e98, #1f4a7b);
            background: -webkit-radial-gradient(90deg, #4c93b1, #336e98, #1f4a7b);
            transition: transform 0.005s;
            width: 350px;
            height: em(150);
            transition: transform 0.005s;
            @include respond(tab-port) {
              width: 300px;
            }
            @include respond(phone-small) {
              width: 250px;
            }
            display: grid;
            grid-template-columns: 40% 60%;
            grid-template-rows: 50% 50%;
            border-radius: em(15);
            padding: em(10);
            align-items: center;
            column-gap: em(0);

            .officedept {
              display: flex;

              .dept {
                color: white;
                font-size: rem(14);
              }
              .logo {
                border-radius: em(50);
                display: flex;
                padding: em(0) em(5);
              }
            }
            .specs {
              display: flex;

              font-weight: 200;
              font-size: rem(14);
              color: white;
              font-family: var(--font-inter);
            }
          }
        }
      }
    }
    .bricks {
      width: 100%;
      display: flex;

      @include respond(tab-port) {
        display: none;
      }
      @include respond(phone) {
      }
      margin-right: em(40);
      @include respond(tab-port) {
        margin-right: em(0);
        padding: em(20) em(40) em(20) em(0);
      }
      padding: em(20) em(80) em(20) em(0);
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}

.aboutcontainer {
  background: rgba(43, 171, 225, 0.1);
}
