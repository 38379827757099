@use '../utils/' as *;

.orch {
  display: flex;
  align-items: center;
  justify-content: center;
    min-height: 100vh;
  @include respond(tab-port) {
display: block;      }
}
