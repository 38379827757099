@use '../utils/' as *;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    color: var(--main);
    font-weight: bold;
  }
  .dir {
    &.-listing {
      display: none;
      @include respond(phone) {
        display: block;
      }
    }
    .button {
      &.-listing {
        @include respond(phone) {
          display: none;
        }
      }
    }
  }
  .title {
    &.review {
      color: var(--main);
    }
  }
}
