@use '../utils/' as *;

.tnc-dialog {
  @include respond(phone) {
  }
  &.-sub {
    .MuiPaper-root {
      width: 90%;
      top: 2rem;
      max-width: unset;
    }
  }
  .MuiPaper-root {
    width: 90%;
    // height: 80%;
    top: 2rem;
    max-width: unset;
  }
  .MuiDialogActions-root {
    display: block;
  }
  .dialogtitle {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      @include respond(phone) {
        font-size: rem(20);
      }
      color: var(--blockmain-40);
      font-size: rem(20);
      display: inline-block;
      font-weight: 500;
    }
  }
  .dialogcontent {
    background-color: var(--blockblue-10);
    border-radius: em(10);
    // margin: em(10);
    display: flex;
    flex-direction: column;
    align-items: center;

    .agreementform {
      width: 100%;
      margin-bottom: em(10);

      .authform {
        padding: em(10);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .formdetails {
          width: 100%;
          display: flex;
          margin: em(10) 0;
          padding: em(5) 0;
          justify-content: space-evenly;
          transition: 1s all ease;
          -o-transition: 1s all ease;
          -ms-transition: 1s all ease;
          -moz-transition: 1s all ease;
          -webkit-transition: 1s all ease;
          align-items: center;
          @include respond(tab-land) {
            width: 60%;

            .text {
              display: none;
            }
          }
          @include respond(tab-port) {
            width: 80%;
          }
          @include respond(phone) {
            width: 100%;
            flex-direction: column;
            div {
              margin-bottom: em(5);
            }
          }
        }
        .text {
          &.-alt {
            display: none;
            @include respond(tab-land) {
              display: block;
            }
            @include respond(phone) {
              margin-bottom: em(10);
            }
          }
        }
        .formactions {
          display: flex;
          justify-content: space-between;
          width: 50%;
          align-content: center;
          @include respond(phone) {
            width: 90%;
          }
        }
      }
    }
  }

  .viewerframe {
    width: 100%;
    height: 90%;
  }
  .dialogactions {
    margin: em(20);
    .actionBtns {
      display: flex;
      justify-content: space-around;

      @include respond(tab-port) {
        flex-direction: column-reverse;
        align-items: center;
      }
    }
  }
}
