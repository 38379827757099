:root {
  --bg: hsl(240, 20%, 99%);
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --new: hsl(195, 96%, 62%);
  --pay: hsla(195, 96%, 62%, 0.1);

  --main: rgba(30, 116, 187, 1);
  --main-1: rgba(102, 162, 212, 1);
  --main-2: #3f88c5;
  --main-3: rgba(9, 91, 159, 1);
  --main-4: rgba(6, 70, 122, 1);

  --secondary: rgba(41, 59, 143, 1);
  --secondary-1: rgba(102, 116, 180, 1);
  --secondary-2: rgba(67, 82, 155, 1);
  --secondary-3: rgba(26, 41, 114, 1);
  --secondary-4: rgba(10, 24, 87, 1);

  --light: rgba(235, 250, 255, 1);
  --light-1: rgba(255, 255, 255, 1);
  --light-2: rgba(255, 255, 255, 1);
  --light-3: rgba(174, 235, 255, 1);
  --light-4: rgba(125, 222, 254, 1);

  --text: rgba(1, 13, 76, 1);
  --text-1: rgba(7, 26, 126, 1);
  --text-2: rgba(3, 19, 103, 1);
  --text-3: rgba(0, 7, 44, 1);
  --text-4: rgba(0, 0, 2, 1);

  --secondary-10: hsl(336, 50%, 96%);
  --secondary-20: hsl(335, 79%, 91%);
  --secondary-30: hsl(335, 88%, 84%);
  --secondary-40: hsl(335, 87%, 76%);
  --secondary-50: hsl(335, 81%, 63%);
  --secondary-60: hsl(335, 71%, 49%);
  --secondary-70: hsl(335, 81%, 38%);
  --secondary-80: hsl(335, 77%, 31%);
  --secondary-90: hsl(335, 64%, 23%);
  --secondary-100: hsl(336, 52%, 17%);

  --success-10: hsl(177, 54%, 93%);
  --success-20: hsl(177, 66%, 79%);
  --success-30: hsl(176, 59%, 64%);
  --success-40: hsl(176, 49%, 51%);
  --success-50: hsl(176, 60%, 39%);
  --success-60: hsl(176, 70%, 30%);
  --success-70: hsl(176, 72%, 24%);
  --success-80: hsl(176, 73%, 19%);
  --success-90: hsl(176, 69%, 14%);
  --success-100: hsl(176, 65%, 10%);

  --error-10: hsl(33, 79%, 95%);
  --error-20: hsl(33, 88%, 83%);
  --error-30: hsl(33, 86%, 71%);
  --error-40: hsl(33, 79%, 58%);
  --error-50: hsl(33, 85%, 44%);
  --error-60: hsl(33, 100%, 34%);
  --error-70: hsl(33, 96%, 28%);
  --error-80: hsl(33, 87%, 22%);
  --error-90: hsl(33, 75%, 17%);
  --error-100: hsl(32, 62%, 12%);

  --danger-10: hsl(0, 50%, 96%);
  --danger-20: hsl(0, 78%, 91%);
  --danger-30: hsl(0, 87%, 85%);
  --danger-40: hsl(0, 92%, 77%);
  --danger-50: hsl(0, 89%, 64%);
  --danger-60: hsl(0, 78%, 49%);
  --danger-70: hsl(0, 87%, 39%);
  --danger-80: hsl(0, 82%, 31%);
  --danger-90: hsl(0, 67%, 24%);
  --danger-100: hsl(0, 50%, 18%);

  --gray-10: hsl(216, 33%, 97%);
  --gray-20: hsl(216, 33%, 94%);
  --gray-30: hsl(218, 31%, 90%);
  --gray-40: hsl(218, 28%, 83%);
  --gray-50: hsl(217, 22%, 74%);
  --gray-60: hsl(217, 15%, 61%);
  --gray-70: hsl(217, 10%, 49%);
  --gray-80: hsl(218, 11%, 39%);
  --gray-90: hsl(215, 11%, 30%);
  --gray-100: hsl(217, 11%, 23%);

  --blockmain-40: #1e74bb;
  --blockblue-10: #f5fdfe;
  --blocksecondary-70: #010e4c;

  .Toastify__toast--error {
    border-radius: 10px !important;
    background: #faf0f0 !important;
    color: #de1b1b !important;
  }
  .Toastify__toast--success {
    border-radius: 10px !important;
    background: var(--success-40) !important;
    color: var(--bg);
  }
  .Toastify__toast--info {
    border-radius: 10px !important;
    background: #3f88c5 !important;
    color: var(--bg);
  }

  .Toastify__toast-container--top-left {
    top: 20%;
    transform: translate(0, -20%);
}
}
