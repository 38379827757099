@use '../utils/' as *;

.footer {
  padding: em(10);
  background-color: #f7feff;
  display: flex;
  justify-content: center;
  .inner-wrapper {
    padding: 0 em(20);
    .row {
      display: flex;
      flex-wrap: wrap;
      & > *:nth-child(1) {
        flex: 1 1 30%;
        min-width: 25ch;
      }
      & > *:nth-child(2) {
        flex: 1 1 70%;
      }
      .logo_div {
        // width: em(500);
        margin-bottom: em(15);
        .logo {
          width: em(120);
          height: em(100);
          a {
            img {
              width: 100%;
              object-fit: contain;
            }
          }
        }
        p {
          padding-left: em(10);
          color: rgba(0, 40, 78, 0.8);
        }
        ul {
          display: flex;
          @include respond(phone) {
            justify-content: center;
          }

          li {
            margin-left: em(10);
            .social_icons {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #eef4fb;
              padding: em(20);
              border-radius: em(5);
              transition: 300ms all ease-in-out;
              border: 1px solid white;
              &:hover {
                background-color: #738bae;
                color: white;
              }
            }
          }
        }
      }
      .links_div {
        display: flex;
        flex-wrap: wrap;
        & > * {
          flex: 1;
          text-align: center;
          min-width: 20ch;
        }
        .links {
          h5 {
            font-size: em(24);
            margin-bottom: em(10);
          }
          ul {
            display: flex;
            flex-direction: column;
            color: rgba(0, 40, 78, 0.8);
            li {
              transition: 300ms all ease-in-out;
              &:hover {
                color: #1e75bb;
              }
            }
          }
        }
        .services {
          h5 {
            font-size: em(24);
            margin-bottom: em(10);
          }
          ul {
            display: flex;
            flex-direction: column;
            color: rgba(0, 40, 78, 0.8);
            li {
              transition: 300ms all ease-in-out;
              &:hover {
                color: #1e75bb;
              }
            }
          }
        }
        .legal {
          h5 {
            font-size: em(24);
            margin-bottom: em(10);
          }
          ul {
            display: flex;
            flex-direction: column;
            color: rgba(0, 40, 78, 0.8);
            li {
              transition: 300ms all ease-in-out;
              &:hover {
                color: #1e75bb;
              }
            }
          }
        }
      }
    }
    .disclaimer {
      display: flex;
      flex-direction: column;
      color: rgba(0, 40, 78, 0.8);
      p {
        margin-bottom: em(10);
        font-size: em(15);
        line-height: em(20);
      }
    }
    .footer_bottom {
      margin-top: em(30);
      display: flex;
      flex-wrap: wrap-reverse;
      justify-content: space-between;
      padding: em(10) 0;
      border-top: 1px solid rgb(235, 229, 229);
      font-size: 17px;
      @include respond(phone) {
        justify-content: center;
        align-items: center;
      }
      .footer_bottom_left {
        display: flex;
        p {
          margin-block-end: unset !important;
          padding: em(15) 0;
          color: rgba(0, 40, 78, 0.8);
          @include respond(phone) {
            padding: 0;
          }
        }
      }
      .footer_bottom_right {
        ul {
          display: flex;
          gap: em(10);
          color: rgba(0, 40, 78, 0.8);
          @include respond(phone) {
            padding: 0;
          }
          li {
            transition: 300ms all ease-in-out;
            &:hover {
              color: #1e75bb;
            }
          }
        }
      }
    }
  }
}

.footerlanding {
  color: white;
  font-family: var(--font-inter);
  background: rgb(22, 39, 47);
  display: flex;
  flex-direction: column;
  padding: em(20) em(40);
  row-gap: em(20);
  .mainlinks {
    display: flex;
    justify-content: space-between;
    @include respond(tab-port) {
      flex-direction: column;
      row-gap: em(10);
    }
    .sociallinks,
    .otherpages,
    .legallinks {
      line-height: em(20);
      font-weight: 200;
      font-size: rem(14);

      .soc {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        @include respond(tab-port) {
          justify-items: center;
          grid-template-columns: repeat(3, 1fr);
          border-bottom: solid rgba(255, 255, 255, 0.4) em(1);
          padding-bottom: em(20);
        }
        gap: em(20);
        &.-other {
          grid-template-columns: repeat(3, 1fr);
          @include respond(tab-land) {
            grid-template-columns: repeat(2, 1fr);
          }
          @include respond(tab-port) {
            grid-template-columns: repeat(3, 1fr);
          }
        }
        &.-about {
          grid-template-columns: repeat(2, 1fr);

          @include respond(tab-port) {
            grid-template-columns: repeat(3, 1fr);
          }
        }
        > .socitem {
          &:hover {
            color: rgb(255, 255, 255, 0.8);
          }
          .social_icons{ 
            width: em(80);
          }
        }
      }
    }
  }
  .disclaimer {
    p {
      font-size: rem(10);
      font-weight: 400;
      line-height: em(20);
    }
  }
  .date {
    display: flex;
    justify-content: center;
    font-weight: 200;
    p {
      font-size: rem(14);
      text-align: center;
      color: white;
    }
  }
}
