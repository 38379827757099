@use '../utils/' as *;

.auth {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  width: 50%;
  height: 100%;
  padding: em(12);
  z-index: 3000;
  @include respond(tab-port) {
    width: 60%;
    padding: 0;
  }
  @include respond(phone) {
    width: 100%;
  }

  &.-skeleton {
    border: none;
    background-color: none;
  }
  &.-verify {
    top: 15%;
    background-color: none;
    z-index: 0;
    margin: em(20);
    @include respond(phone) {
      top: 20%;
    }
  }
  &.-status {
    top: 50%;
    transform: translate(-50%, -50%);
    border: none;
    background-color: none;
  }
  &.-profile {
    position: relative;
    border: none;
    height: auto;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 0;
    padding: 0;
    background-color: var(--bg);
    border-radius: 0;
  }
  &.-whitelist {
    position: relative;
    border-radius: 0;
    border: none;
    height: initial;
    min-height: auto;
    align-items: center;
    background-color: var(--bg);
    padding: 0;
    z-index: 0;
  }
  &.-confirm {
    max-height: 20vh;
  }
  -webkit-transition: all 0.3 ease;
  -moz-transition: all 0.3 ease;
  -ms-transition: all 0.3 ease;
  -o-transition: all 0.3 ease;
  transition: all 0.3 ease;
  min-height: 50vh;
  .auth-container {
    margin-top: em(10);
    width: 50%;
    &.-whitelist {
      width: 100%;
      margin-top: 0;
    }
    .homepage {
      width: 40%;
      height: em(50);
      margin-bottom: em(20);
    }
    @include respond(tab-land) {
      width: 80%;
    }
    @include respond(tab-port) {
      width: 90%;
    }
    .auth-toggle {
      display: flex;
      width: 100%;
      justify-content: space-around;
      @include respond(tab-land) {
        justify-content: space-between;
        width: 100%;
      }
      padding: em(5);
      font-size: rem(16);
      -o-transition: all 0.3 ease;
      -ms-transition: all 0.3 ease;
      -moz-transition: all 0.3 ease;
      -webkit-transition: all 0.3 ease;
      transition: all 0.3 ease;

      button {
        color: var(--main);
        width: 100%;
        font-weight: 300;
      }

      margin: em(10) 0;

      @include respond(phone) {
        width: 80%;
      }
      .activetoggle {
        text-decoration: none;
        position: relative;
        -o-transition: all 0.3 ease;
        -ms-transition: all 0.3 ease;
        -moz-transition: all 0.3 ease;
        -webkit-transition: all 0.3 ease;
        transition: all 0.3 ease;
        font-weight: 400;
        &::after {
          position: absolute;
          content: '';
          height: em(2);
          bottom: em(-8);
          margin: 0 auto;
          left: 0;
          right: 0;
          width: 100%;
          background: var(--main);
          -o-transition: all 0.3 ease;
          -ms-transition: all 0.3 ease;
          -moz-transition: all 0.3 ease;
          -webkit-transition: all 0.3 ease;
          transition: all 0.3 ease;
        }
      }
    }

    .icon_div {
      // position: absolute;
      // top: 30px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 44px;

      @include respond(phone) {
        padding: 0 20px;
      }
      .icon_div_inner {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-radius: em(25);
        color: var(--main);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        .icon {
          color: inherit;
        }
        span {
          color: inherit;
          font-size: small;
        }
        &:hover {
          background-color: #1e75bb;
          color: white;
        }
      }
    }

    .auth-panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: small;
      color: var(--main);
      padding: em(10) 0;
      width: 100%;
      > .authform {
        width: 100%;
        display: flex;
        flex-direction: column;
        transition: 1s all ease;
        -o-transition: 1s all ease;
        -ms-transition: 1s all ease;
        -moz-transition: 1s all ease;
        -webkit-transition: 1s all ease;
        &.-whitelist {
          align-items: flex-start;
          padding: em(10) em(15);
        }
        align-items: center;
        > .details {
          display: flex;
          flex-direction: column;
          margin-bottom: em(10);
          transition: 300ms all ease-in-out;
          width: 80%;

          &.-profile,
          &.-whitelist {
            width: 80%;
            @include respond(tab-land) {
              width: 100%;
            }
            @include respond(tab-port) {
              width: 120%;
            }
            @include respond(phone) {
              width: 90%;
            }
            @include respond(phone-small) {
              width: 100%;
            }

            .inputcontainer {
              input {
                background-color: var(--light);
              }
            }
            .helpers {
              color: var(--main-3);
              margin: em(10) 0;
              padding: em(10);
              text-align: center;
              font-weight: 400;
              &.-whitelist {
                text-align: left;
              }
              &.-error {
                color: var(--danger-40);
              }
              &.-info {
                color: var(--main-3);
              }
            }
            &__pwd {
              width: 40%;
              @include respond(phone) {
                width: 90%;
              }
              color: var(--gray-90);
              > .label {
                margin: em(10) 0;
              }
              .inputcontainer {
                input {
                  background-color: var(--gray-20);
                  caret-color: var(--gray-80);
                  &:focus {
                    outline: 1px solid var(--gray-80);
                  }
                }
              }
            }
            &__cty {
              width: 40%;
              @include respond(phone) {
                width: 90%;
              }
              p {
                color: var(--main);
              }
              .options {
                width: 40%;
                @include respond(phone) {
                  width: 90%;
                }
                margin-top: em(20);
                label {
                  font-weight: 600;
                  input[type='radio'] {
                    margin-right: em(20);
                  }
                }
              }
            }
          }
          > .label {
            text-align: left;
            font-weight: 500;
            margin-bottom: em(20);
            &.-whitelist {
              font-weight: 600;
            }
          }
          p,
          small {
            margin: 0;
            padding: em(5);
            color: var(--danger-50);
            text-align: left;
          }

          .inputcontainer {
            position: relative;
            &.-recaptcha {
              margin: em(20) 0;

              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .input {
              width: 100%;
              border: none;
              background-color: none;
              padding-right: 0 em(40);
              color: var(--text-200);
              outline: em(1) solid var(--main-1);
              -webkit-appearance: none;
              -ms-appearance: none;
              -moz-appearance: none;
              appearance: none;
              padding-left: 20px;
              border-radius: em(25);
              font-weight: 600;
              height: em(40);
              caret-color: var(--main-1);
              &.-whitelist {
                width: 100%;
              }
              &::placeholder {
                font-weight: lighter;
                color: var(--main);
              }
              &:focus {
                outline: em(2) solid var(--main-1);
              }
            }
            .icon-pwd {
              position: absolute;
              right: em(15);
              top: em(8);
              &.-wkhitelist {
                right: -50px;
              }
            }
          }
          > .select {
            height: em(40);
            border: none;
            outline: em(1) solid var(--main-1);
            color: var(--main);
            border-radius: em(25);
            padding-left: 20px;
            caret-color: var(--main-60);
            &:focus {
              outline: 1px solid var(--main-1);
            }
          }
        }
        .submit {
          &.-profile {
            width: 40%;
            @include respond(phone) {
              width: 90%;
            }
          }
          &.-verify {
            margin: em(20) 0;
          }
          .signinbutton {
            color: var(--main);
            border-radius: em(25);
            border: em(2) solid var(--main-1);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            line-height: 1.5;
            padding: 0.25em 0.5em;
            min-width: 10ch;
            min-height: em(16);
            transition: 220ms all ease-in-out;
            margin: em(40) 0;

            &:hover {
              background-color: var(--main);

              color: var(--bg);
            }
            &:focus {
              outline-style: solid;
              outline-color: transparent;
              box-shadow: 0 0 0 2px var(--primary-600);
            }
            &:disabled {
              color: var(--gray-80);
              border: em(2) solid var(--gray-80);

              &:hover {
                background-color: var(--gray-10);
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }

    .auth-footer {
      margin: em(10) 0;
      font-size: small;
      font-weight: 300;
      color: var(--main);

      @include respond(phone) {
        padding: 0 em(20);
      }

      p {
        font-size: rem(14);
        text-align: center;
        @include respond(phone) {
          display: flex;
          flex-direction: column;
        }
      }

      .auth-span {
        color: var(--main);
        cursor: pointer;
        padding: 0 1em;
        transition: 300ms all ease-in-out;
      }
      .auth-span__disabled {
        color: var(--gray-40);
        border-radius: em(50);
        cursor: not-allowed;
        pointer-events: none;
      }

      .auth-button {
        display: flex;
        justify-content: space-around;
        margin: em(20);
      }
    }
  }
}
.auth-img {
  @include respond(tab-port) {
    display: none;
    align-items: normal;
    justify-content: start;
  }
  display: flex;
  background: #1c92d2;
  background: -webkit-linear-gradient(to left, #f2fcfe, #1c92d2);
  background: linear-gradient(to left, #f2fcfe, #1c92d2);
  width: 50%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .map {
    display: flex;
    width: 100%;
    justify-content: center;
    img {
      width: 90%;
      height: auto;
    }
  }
}
