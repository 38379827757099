@use '../utils/' as *;

.pay {
  display: flex;
  flex-direction: column;
  background-color: var(--gray-20);
  min-height: 100vh;
  .checkout {
    align-self: center;
    margin: em(20);
    background: var(--bg);
    padding: em(10);
    width: 50%;
    &.-mobile {
      display: none;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      table {
        width: 100%;
      }
      @include respond(tab-port) {
        display: block;
        width: 90%;
        table {
          thead {
            tr {
              th {
                margin: em(40) 0;
                text-align: center;
                overflow: hidden;
                padding: 0 em(4);
              }
            }
          }

          tbody {
            tr {
              td {
                  overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
                text-align: center;
              }
            }
          }
        }
      }
    }
    @include respond(tab-port) {
      width: 100%;
      display: none;
    }

    border-radius: em(25);
    table {
      thead {
        th {
          padding: em(0) em(10);

          color: var(--text);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      tbody {
        td {
          padding: em(10);
          color: var(--main);
          font-weight: 600;
          vertical-align: middle;
          height: 100%;
          text-align: right;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          img {
            width: em(80);
            height: em(60);
            object-fit: fill;
          }
        }
      }
    }
  }
  .option {
    align-self: center;
    display: flex;
    flex-direction: column;
    background: var(--bg);
    border-radius: em(25);
    width: 50%;
    @include respond(tab-port) {
      width: 90%;
    }
    padding: em(10);
    -webkit-transition: all 0.3 ease;
    -moz-transition: all 0.3 ease;
    -ms-transition: all 0.3 ease;
    -o-transition: all 0.3 ease;
    transition: all 0.3 ease;
    h4 {
      color: var(--main);
      font-weight: 600;
      text-align: center;
      text-transform: capitalize;
    }
    div {
      padding: em(10) em(5);
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .dir {
        -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
        animation: heartbeat 1.5s ease-in-out infinite both;
      }
      @include respond(phone) {
        .dir {
          display: none;
        }
      }
    }
  }
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.provider {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--bg);
  .info {
    margin: em(40) 0;
    width: 50%;
    @include respond(tab-land) {
      width: 80%;
    }
  }
  .frame {
    @include respond(tab-land) {
      width: 80%;
    }
    @include respond(phone-small) {
      width: 95%;
    }
    width: 50%;
    min-height: 80vh;
    iframe {
      width: 100%;
      height: 100vh;
      border: 2px solid var(--success-20);
      @include respond(phone) {
        border: none;
      }
      border-radius: em(10);
    }
  }
}
