@use '../utils/' as *;

.relisting-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  &.-mini {
    min-height: auto;
    height: 40vh;
    .form {
      > .content {
        display: flex;
        padding: em(10);
        justify-content: space-between;
        div {
          padding: em(10);
        }
      }
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    .fieldsection {
      font-weight: bold;
      color: var(--main);
      text-align: center;
      margin: em(10) 0;
      background-color: var(--gray-10);
      padding: em(5);
      p,
      h5 {
        padding: 0;
        margin: 0;
      }
    }
    > .formcontent {
      color: var(--main);
      display: grid;
      grid-template-columns: repeat(2, em(500));
      grid-row-gap: em(20);
      margin: em(20);
      width: 80%;
      align-content: center;
      justify-items: center;
      border-radius: em(5);
      padding: em(10);

      @include respond(tab-land) {
        width: 80%;
        grid-template-columns: repeat(2, em(300));
        justify-items: center;
      }
      @include respond(phone) {
        width: 100%;
        grid-template-columns: repeat(1, em(300));
        justify-items: center;
      }
    }
    > .formsubmit {
      position: relative;
      min-height: 10vh;
      > .button {
        &.-listing {
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.token-panel {
  border: 1px solid var(--gray-20);
  background-color: var(--light);
  border-radius: em(25);
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 em(20);
  &.-token-dim {
    background-color: var(--gray-20);
  }
  @include respond(tab-land) {
    width: 100%;
  }
  @include respond(phone) {
    flex-direction: column;
  }

  .token-form {
    padding: em(20) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include respond(tab-land) {
      justify-content: space-around;
    }
    @include respond(phone) {
      flex-direction: column;
      flex-grow: 0;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .details {
      width: 30%;
      display: flex;
      flex-direction: column;
      margin-bottom: em(10);
      transition: 300ms all ease-in-out;
      position: relative;
      @include respond(phone) {
        width: 100%;
        margin-bottom: em(20);
      }
      > .label {
        text-align: left;
        font-weight: 500;
        margin-bottom: em(5);
        color: var(--main);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      > .labeldim {
        text-align: left;
        font-weight: 500;
        margin-bottom: em(5);
        color: var(--gray-60);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      p,
      small {
        margin: 0;
        padding: em(5);
        text-align: left;
        color: var(--danger-50);
      }
      .errorlog {
        padding: em(5);
        text-align: left;
        color: var(--danger-50);
        font-size: bold;
        font-weight: 500;
        // position: absolute;
        // z-index: 20;
        // bottom: 0;
        // left: 0;
      }
      .inputcontainer {
        position: relative;
        .input {
          width: 100%;
          border: none;
          background-color: none;
          padding-right: 0 em(40);
          color: var(--text-1);
          -webkit-appearance: none;
          -ms-appearance: none;
          -moz-appearance: none;
          appearance: none;
          padding-left: em(20);
          border-radius: em(25);
          font-weight: 600;
          height: em(40);
          caret-color: var(--main-1);
          &.-dollarValue {
            background-color: var(--light-1);
          }
          &:focus {
            outline: 1px solid var(--main-1);
          }
        }
      }
    }

    .token-submit {
      margin-top: em(20);
      .signinbutton {
        color: var(--main);
        border-radius: em(15);
        border: em(2) solid var(--main-1);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1.5;
        padding: 0.25em 0.5em;
        min-width: 10ch;
        min-height: em(16);
        transition: 220ms all ease-in-out;

        &:hover {
          background-color: var(--main-3);
          color: var(--bg);
        }
        &:focus {
          outline-style: solid;
          outline-color: transparent;
          box-shadow: 0 0 0 2px var(--primary-3);
        }
      }
      @include respond(phone) {
        margin-bottom: em(20);
      }
    }
  }
}

.errorlog {
  padding: 0;
  z-index: 300;
  color: var(--secondary-60);
  font-weight: 600;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

